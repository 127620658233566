import React, { useState } from "react";
import axios from "axios";

const NewsletterSubscription = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      setMessage("Please enter a valid email address.");
      return;
    }

    try {
      const response = await axios.post(
        "https://skill-exchange-hub.com/api/subscribe",
        { email }
      );
      setMessage("Thank you for subscribing!");
      setEmail("");
    } catch (error) {
      setMessage("There was an error subscribing. Please try again later.");
    }
  };

  return (
    <div className="bg-white text-gray-900 p-6 rounded text-center">
      <h2 className="text-3xl font-bold mb-4">Subscribe to our Newsletter</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <input
            className="w-full p-2 border border-gray-300 rounded"
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <button
          className="w-full text-white p-2 rounded"
          style={{
            backgroundImage:
              "linear-gradient(-225deg, #231557 0%, #44107A 29%, #FF1361 67%, #FFF800 100%)",
          }}
          type="submit"
        >
          Subscribe
        </button>
      </form>
      {message && <p className="mt-4">{message}</p>}
    </div>
  );
};

export default NewsletterSubscription;
