import React, { useState } from "react"; // Removed useEffect
import NewsletterSubscription from "./NewsletterSubscription";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Parallax } from "react-parallax";
import heroImage from "./images/hero.jpg";
import parallaxImage from "./images/parallax.jpg";
import userProfileImage from "./images/img-1.jpg";
import skillExchangeMarketplaceImage from "./images/img-2.jpg";
import interactiveToolsImage from "./images/img-3.jpg";
import communityFeaturesImage from "./images/img-4.jpg";
import gamificationImage from "./images/img-5.jpg";
import skillVerificationImage from "./images/img-6.jpg";
import company1 from "./images/company1.jpg";
//import company2 from "./images/company2.jpg";
import company3 from "./images/company3.jpg";
//import company4 from "./images/company4.jpg";
import company5 from "./images/company5.jpg";
import company6 from "./images/company6.jpg";
//import company7 from "./images/company7.jpg";
//import company8 from "./images/company8.jpg";
import { successStories } from "./successStoriesData";
import logo from "./images/logo.png";

import { faQuoteLeft, faQuoteRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const App = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form Data Submitted: ", formData);

    try {
      const response = await fetch("https://skill-exchange-hub.com/signup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert("Sign-up successful!");
      } else {
        alert("Sign-up failed.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Sign-up failed. Please try again later.");
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="min-h-screen bg-gray-100 text-gray-900 flex flex-col">
      <header
        className="text-white p-6"
        style={{
          backgroundImage:
            "linear-gradient(-225deg, #231557 0%, #44107A 29%, #FF1361 67%, #FFF800 100%)",
        }}
      >
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <img
              src={logo}
              alt="Skill Exchange Hub Logo"
              className="h-12 mr-4"
            />
            <div>
              <h1 className="text-3xl font-bold">Skill Exchange Hub</h1>
              <p className="mt-2">Share, exchange, and learn new skills</p>
            </div>
          </div>
          <nav>
            <ul className="flex space-x-4">
              <li>
                <a href="#hero" className="text-white hover:text-gray-200">
                  Home
                </a>
              </li>
              <li>
                <a href="#about" className="text-white hover:text-gray-200">
                  About
                </a>
              </li>
              <li>
                <a href="#signup" className="text-white hover:text-gray-200">
                  Sign Up
                </a>
              </li>
              <li>
                <a href="#features" className="text-white hover:text-gray-200">
                  Features
                </a>
              </li>
              <li>
                <a href="#partners" className="text-white hover:text-gray-200">
                  Partners
                </a>
              </li>
              <li>
                <a
                  href="#user-stories"
                  className="text-white hover:text-gray-200"
                >
                  User Stories
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </header>

      <main className="flex-grow">
        <section
          id="hero"
          className="relative bg-cover bg-center h-screen text-white flex items-center justify-center"
          style={{ backgroundImage: `url(${heroImage})` }}
        >
          <div className="bg-black bg-opacity-50 p-10 rounded flex flex-col items-center justify-center text-center">
            <h2 className="text-5xl font-bold mb-4">
              Welcome to Skill Exchange Hub
            </h2>
            <p className="text-lg">
              Where individuals share, exchange, and learn new skills.
            </p>
          </div>
        </section>

        <section id="about" className="bg-white text-gray-900 py-6">
          <div className="max-w-6xl mx-auto gradient-border p-8">
            <h2 className="text-3xl font-bold mb-4">
              About Skill Exchange Hub
            </h2>
            <p className="text-lg leading-relaxed">
              Skill Exchange Hub is a platform designed to connect individuals
              looking to share, exchange, and learn new skills. Our mission is
              to foster a community of lifelong learners and skilled
              professionals who can benefit from each other's knowledge and
              experience. Whether you're looking to improve your professional
              skills, learn a new hobby, or offer your expertise to others,
              Skill Exchange Hub provides the tools and resources you need to
              succeed.
            </p>
            <p className="text-lg leading-relaxed mt-4">
              Our user-friendly platform offers various interactive features,
              including workshops, webinars, and one-on-one mentoring sessions.
              Users can create detailed profiles, connect with like-minded
              individuals, and participate in group discussions to enhance their
              learning experience.
            </p>
            <p className="text-lg leading-relaxed mt-4">
              Skill Exchange Hub also incorporates gamification elements to keep
              users motivated, such as earning badges and climbing leaderboards.
              Our verification system allows users to get their skills endorsed
              by experts, adding credibility within the community.
            </p>
            <p className="text-lg leading-relaxed mt-4">
              Join Skill Exchange Hub today and become part of a vibrant
              community dedicated to continuous learning and personal growth.
            </p>
          </div>
        </section>

        <Parallax bgImage={parallaxImage} strength={500}>
          <div
            id="signup"
            className="relative h-96 flex items-center justify-center m-10"
          >
            <div className="bg-black bg-opacity-50 p-6 rounded text-center text-white ">
              <h2 className="text-3xl font-bold mb-4">Join our Community</h2>
              <p className="text-lg mb-6">
                Sign up now to start sharing, exchanging, and learning new
                skills.
              </p>
              <form
                onSubmit={handleSubmit}
                className="bg-white text-gray-900 p-6 rounded"
              >
                <div className="mb-4">
                  <label className="block text-left mb-2" htmlFor="name">
                    Name
                  </label>
                  <input
                    className="w-full p-2 border border-gray-300 rounded"
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-left mb-2" htmlFor="email">
                    Email
                  </label>
                  <input
                    className="w-full p-2 border border-gray-300 rounded"
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <button
                  className="w-full text-white p-2 rounded"
                  style={{
                    backgroundImage:
                      "linear-gradient(-225deg, #231557 0%, #44107A 29%, #FF1361 67%, #FFF800 100%)",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundImage =
                      "linear-gradient(225deg, #FF1361 0%, #44107A 29%, #231557 67%, #FFF800 100%)")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundImage =
                      "linear-gradient(-225deg, #231557 0%, #44107A 29%, #FF1361 67%, #FFF800 100%)")
                  }
                  type="submit"
                >
                  Sign Up
                </button>
              </form>
            </div>
          </div>
        </Parallax>

        {/* Features Section */}
        <section id="features" className="p-8 bg-gray-50">
          <h2 className="text-4xl font-bold text-center mb-8">Features</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <div className="relative flex flex-col items-center text-center shadow-lg transition-transform transform hover:scale-105 p-6 rounded-lg bg-white">
              <img
                src={userProfileImage}
                alt="User Profiles"
                className="w-full h-48 object-cover rounded-t-lg"
              />
              <div className="p-4">
                <h3 className="text-2xl font-semibold">User Profiles</h3>
                <p className="text-md mt-2">
                  List skills offered and desired, with experience levels.
                </p>
              </div>
            </div>
            <div className="relative flex flex-col items-center text-center shadow-lg transition-transform transform hover:scale-105 p-6 rounded-lg bg-white">
              <img
                src={skillExchangeMarketplaceImage}
                alt="Skill Exchange Marketplace"
                className="w-full h-48 object-cover rounded-t-lg"
              />
              <div className="p-4">
                <h3 className="text-2xl font-semibold">
                  Skill Exchange Marketplace
                </h3>
                <p className="text-md mt-2">
                  Match users based on skill needs and schedule sessions.
                </p>
              </div>
            </div>
            <div className="relative flex flex-col items-center text-center shadow-lg transition-transform transform hover:scale-105 p-6 rounded-lg bg-white">
              <img
                src={interactiveToolsImage}
                alt="Interactive Tools"
                className="w-full h-48 object-cover rounded-t-lg"
              />
              <div className="p-4">
                <h3 className="text-2xl font-semibold">Interactive Tools</h3>
                <p className="text-md mt-2">
                  Video conferencing, resource sharing, and interactive
                  whiteboard.
                </p>
              </div>
            </div>
            <div className="relative flex flex-col items-center text-center shadow-lg transition-transform transform hover:scale-105 p-6 rounded-lg bg-white">
              <img
                src={communityFeaturesImage}
                alt="Community Features"
                className="w-full h-48 object-cover rounded-t-lg"
              />
              <div className="p-4">
                <h3 className="text-2xl font-semibold">Community Features</h3>
                <p className="text-md mt-2">
                  Forums, reviews, and mentorship programs.
                </p>
              </div>
            </div>
            <div className="relative flex flex-col items-center text-center shadow-lg transition-transform transform hover:scale-105 p-6 rounded-lg bg-white">
              <img
                src={gamificationImage}
                alt="Gamification"
                className="w-full h-48 object-cover rounded-t-lg"
              />
              <div className="p-4">
                <h3 className="text-2xl font-semibold">Gamification</h3>
                <p className="text-md mt-2">
                  Badges, achievements, and leaderboards.
                </p>
              </div>
            </div>
            <div className="relative flex flex-col items-center text-center shadow-lg transition-transform transform hover:scale-105 p-6 rounded-lg bg-white">
              <img
                src={skillVerificationImage}
                alt="Skill Verification"
                className="w-full h-48 object-cover rounded-t-lg"
              />
              <div className="p-4">
                <h3 className="text-2xl font-semibold">Skill Verification</h3>
                <p className="text-md mt-2">
                  Users can get their skills verified by experts to build
                  credibility.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section id="partners" className="my-6 p-6">
          <h2 className="text-3xl font-bold flex flex-col items-center justify-center mb-6">
            Partners
          </h2>
          <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-6">
            <div className="flex flex-col items-center bg-white p-4 rounded-lg shadow-lg">
              <img
                src={company1}
                alt="Brain storm"
                className="w-32 h-32 object-contain"
              />
            </div>
            {/*} <div className="flex flex-col items-center bg-white p-4 rounded-lg shadow-lg">
              <img
                src={company2}
                alt="Infinity"
                className="w-32 h-32 object-contain"
              />
            </div>*/}
            <div className="flex flex-col items-center bg-white p-4 rounded-lg shadow-lg">
              <img
                src={company3}
                alt="IO tech"
                className="w-32 h-32 object-contain"
              />
            </div>
            {/*}   <div className="flex flex-col items-center bg-white p-4 rounded-lg shadow-lg">
              <img
                src={company4}
                alt="Investment"
                className="w-32 h-32 object-contain"
              />
            </div>*/}
            <div className="flex flex-col items-center bg-white p-4 rounded-lg shadow-lg">
              <img
                src={company5}
                alt="Fin Tech"
                className="w-32 h-32 object-contain"
              />
            </div>
            <div className="flex flex-col items-center bg-white p-4 rounded-lg shadow-lg">
              <img
                src={company6}
                alt="Scram Software"
                className="w-32 h-32 object-contain"
              />
            </div>
            {/*
            <div className="flex flex-col items-center bg-white p-4 rounded-lg shadow-lg">
              <img
                src={company7}
                alt="Mira"
                className="w-32 h-32 object-contain"
              />
            </div>
            
            <div className="flex flex-col items-center bg-white p-4 rounded-lg shadow-lg">
              <img
                src={company8}
                alt="Color Studio"
                className="w-32 h-32 object-contain"
              />
            </div>*/}
          </div>
        </section>

        <section id="user-stories" className="p-10 bg-white text-gray-900">
          <div className="max-w-4xl mx-auto">
            <h2 className="text-3xl font-bold mb-4 flex flex-col items-center justify-center">
              User Stories of Success
            </h2>
            <Slider {...settings}>
              {successStories.map((story, index) => (
                <div key={index} className="mx-2 text-center m-10">
                  <img
                    src={story.image}
                    alt={story.name}
                    className="w-40 h-40 object-cover rounded-full mx-auto mb-4"
                  />
                  <FontAwesomeIcon
                    icon={faQuoteLeft}
                    className="text-purple-600"
                  />
                  <p
                    className="mt-2"
                    dangerouslySetInnerHTML={{ __html: story.text }}
                  />
                  <FontAwesomeIcon
                    icon={faQuoteRight}
                    className="text-purple-600"
                  />
                  <h3 className="text-xl font-semibold mt-4">{story.name}</h3>
                </div>
              ))}
            </Slider>
          </div>
        </section>
      </main>

      <footer
        className="text-white p-6 mt-auto flex flex-col items-center justify-center"
        style={{
          backgroundImage:
            "linear-gradient(-225deg, #231557 0%, #44107A 29%, #FF1361 67%, #FFF800 100%)",
        }}
      >
        <NewsletterSubscription /> {/* Add the subscription form here */}
        <p>&copy; 2024 Skill Exchange Hub. All rights reserved.</p>
        <p>
          Contact us at: &nbsp;
          <a href="mailto:Skill Exchange Hub@gmail.com" className="underline">
            Skill Exchange Hub@gmail.com
          </a>
        </p>
      </footer>
    </div>
  );
};

export default App;
