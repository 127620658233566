// successStoriesData.js

import img1m from "./images/img-1-m.jpg";
import img2w from "./images/img-2-w.jpg";
import img3w from "./images/img-3-w.jpg";
import img4m from "./images/img-4-m.jpg";
import img5m from "./images/img-5-m.jpg";
import img6w from "./images/img-6-w.jpg";
import img7w from "./images/img-7-w.jpg";
import img8m from "./images/img-8-m.jpg";
import img9w from "./images/img-9-w.jpg";
import img10m from "./images/img-10-m.jpg";

export const successStories = [
  {
    name: "Michael Johnson",
    text: "<strong>Skill Exchange Hub</strong> helped me connect with industry experts and enhance my programming skills. The platform's resources and community support were invaluable in my learning journey.",
    image: img1m,
  },
  {
    name: "Emily Davis",
    text: "Thanks to <strong>Skill Exchange Hub</strong>, I was able to find a mentor who guided me through my career transition. The interactive tools made learning easy and effective.",
    image: img2w,
  },
  {
    name: "Sarah Thompson",
    text: "<strong>Skill Exchange Hub</strong> has been a game-changer for me. The ability to learn from others and share my own knowledge has been incredibly rewarding.",
    image: img3w,
  },
  {
    name: "James Brown",
    text: "The gamification features of <strong>Skill Exchange Hub</strong> kept me motivated to learn more and achieve my goals.",
    image: img4m,
  },
  {
    name: "David Wilson",
    text: "I was able to get my skills verified by experts on <strong>Skill Exchange Hub</strong>, which greatly improved my credibility in the job market.",
    image: img5m,
  },
  {
    name: "Sophia Martinez",
    text: "The community features on <strong>Skill Exchange Hub</strong>, such as forums and mentorship programs, have been invaluable for my professional growth.",
    image: img6w,
  },
  {
    name: "Olivia White",
    text: "<strong>Skill Exchange Hub</strong>'s interactive tools made it easy to collaborate with others and share resources.",
    image: img7w,
  },
  {
    name: "Liam Taylor",
    text: "Thanks to <strong>Skill Exchange Hub</strong>, I found a mentor who helped me transition to a new career field.",
    image: img8m,
  },
  {
    name: "Isabella Harris",
    text: "The platform's user-friendly interface and extensive resources made it easy for me to learn new skills.",
    image: img9w,
  },
  {
    name: "William Clark",
    text: "I highly recommend <strong>Skill Exchange Hub</strong> to anyone looking to learn new skills or share their expertise with others.",
    image: img10m,
  },
];
